import React from 'react';
import { useTranslation } from 'react-i18next';

const TitleField = ({ field, index, setMapTitles }) => {
  const { t } = useTranslation('paraskartta');
  const { uppercase } = field;

  const handleChange = (e) => {
    setMapTitles((titles) => {
      titles[index].value = uppercase ? e.target.value.toUpperCase() : e.target.value;
      return [...titles];
    });
  };

  return (
    <div className="text-field">
      <div className="text-field__label-wrap">
        <label className="text-field__label" htmlFor={`text-field-${index}`}>
          <p>{field.title}</p>
        </label>
      </div>
      <div className="text-field__input-wrap">
        <input
          id={`text-field-${index}`}
          className="text-field__input"
          type="text"
          maxLength={field.max_length}
          value={field.value}
          onChange={(e) => handleChange(e)}
          placeholder={`${t('options.fill')}`}
        />
      </div>
    </div>
  );
};

export default TitleField;
