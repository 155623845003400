import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronLeftIcon } from '../assets/icons/icon-chevron-left.svg';
import { ReactComponent as OmaKarttaLogo } from '../assets/logos/omakartta-logo.svg';
import { ReactComponent as KarttakeskusLogo } from '../assets/logos/karttakeskus-logo.svg';

const Header = () => {
  const { t } = useTranslation('paraskartta');

  // Handle the language selection
  // const setAppLang = (language) => {
  // 	if (language === "fi") {
  // 		props.setLanguage("fi")
  // 		i18n.changeLanguage("fi")
  // 	} else if (language === "en-US") {
  // 		props.setLanguage("en")
  // 		i18n.changeLanguage("en")
  // 	}
  // 	document.documentElement.lang = language
  // }

  const routeChange = () => {
    let path = document.referer;
    if (path === undefined) {
      path = 'https://www.karttakauppa.fi';
    } else if (path.startsWith('https://www.karttakauppa.fi')) {
      path = 'https://www.karttakauppa.fi';
    } else if (path.startsWith('https://www.metsakauppa.fi')) {
      path = 'https://www.metsakauppa.fi';
    }
    window.location.href = path;
  };

  return (
    <div className="header">
      <div className="header__container">
        <div className="header__grid">
          <div className="header__grid-item header__grid-item--left">
            <button className="button button--transparent" onClick={() => routeChange()}>
              <ChevronLeftIcon />
              {t('header.back_button')}
            </button>
          </div>
          <div className="header__grid-item header__grid-item--center">
            <OmaKarttaLogo />
          </div>
          <div className="header__grid-item header__grid-item--right">
            <KarttakeskusLogo />
            {/* <div className="header__language-switcher">
							<button className="button button--transparent button--language" disabled={i18n.language === "fi" ? true : false} onClick={() => setAppLang("fi")} aria-label="Suomeksi">
								FI
							</button>
							<p aria-hidden="true">/</p>
							<button className="button button--transparent button--language disabled" disabled={i18n.language === "en" ? true : false} onClick={() => setAppLang("en-US")} aria-label="In English">
								EN
							</button>
						</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
