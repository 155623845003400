import React, { createRef } from 'react';
import { Component } from 'react';
import MapGL, { NavigationControl, FlyToInterpolator, WebMercatorViewport } from 'react-map-gl';
import AreaSelector from './AreaSelector.js';
import Search from './Search.js';
import proj4 from 'proj4';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';

// Workaround for map not showing in production build. See more at https://github.com/mapbox/mapbox-gl-js/issues/10173 and https://github.com/visgl/react-map-gl/pull/1365
import mapboxgl from 'mapbox-gl';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      productInfo: {
        width: this.props.dimensions[0],
        height: this.props.dimensions[1],
        mapScale: this.props.dimensions[2],
      },
      viewport: {
        longitude: 25.79102298741991,
        latitude: 61.79955761261888,
        zoom: 6.8,
        bearing: -2.1,
        pitch: 0,
        transitionDuration: 0,
      },
      lastLockViewport: {},
      settings: {
        dragPan: !this.props.selectionLocked,
        dragRotate: false,
        touchRotate: false,
        doubleClickZoom: false,
        scrollZoom: true,
        minZoom: 4.9,
        maxZoom: 15.5,
        keyboard: false,
      },
      lastZoomAreaPolygon: null,
      updateDepthCounter: 0,
      //This is for setting maxBounds for the map
      bounds: [
        [19.246012290853132, 59.6477726439094],
        [31.73359738955013, 70.36596414540806],
      ],
      isToggleOn: true,
      lastMaxZoom: 15.5,
      stateIsNotSynced: false,
    };
    this.handleDelay = this.handleDelay.bind(this);
    this.styles = {
      map: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'inherit',
      },
    };
    this.areaRef = createRef();
    this.callRedraw = this.callRedraw.bind(this);
  }

  updateViewportOnTransitionEnd = () => {
    //console.log("updateViewportOnTransitionEnd")

    let viewport = {};

    viewport = {
      ...this.state.viewport,
      transitionDuration: 0,
    };

    this.setState({
      viewport,
    });
    if (this.state !== undefined) {
      this.callRedraw(this.state.viewport);
    }
    //console.log("center coords: " + viewport.latitude + ", " + viewport.longitude)
  };

  updateCenterCoordinates = () => {
    this.props.setCenterCoordinates([this.state.viewport.longitude, this.state.viewport.latitude]);
    //console.log("we set mid coords in the updateCenterCoordinates -funktion");
    //console.log(this.state.viewport.longitude, this.state.viewport.latitude);
  };

  lockMap = (boolean) => {
    const settings = {
      ...this.state.settings,
      dragPan: boolean,
      scrollZoom: boolean,
    };
    this.setState({ settings });
    //this.callRedraw(this.state.viewport);

    if (this.props.zoomAreaPolygon !== undefined && !boolean) {
      if (this.state.lastLockViewport.zoom !== undefined) {
        if (this.state.lastLockViewport.zoom !== this.state.viewport.zoom) {
          this.goToSelection(boolean);
        }
      } else if (this.state.lastLockViewport.zoom !== this.state.viewport.zoom) {
        const viewport = {
          ...this.state.viewport,
          transitionDuration: 0,
        };
        this.setState({
          viewport,
        });
        this.goToSelection(boolean);
      } else this.goToSelection(boolean);
    }

    const lastLockViewport = {
      ...this.state.viewport,
    };
    this.setState({ lastLockViewport });
  };

  /* eslint no-unused-vars: off */
  componentDidUpdate(prevProps, prevState) {
    // DO NOT TOUCH THIS FUNCTION IF AT ALL POSSIBLE!!! - T: Ged
    // (it's effecting the functionality of the entire rendering process, so even minimal changes very likely break something)
    if (this.state.viewport.maxZoom === 24) {
      const viewport = {
        ...this.state.viewport,
        maxZoom: this.state.lastMaxZoom,
      };
      this.setState({ viewport });
    }
    if (prevProps.selectionLocked !== this.props.selectionLocked) {
      this.updateCenterCoordinates();
      this.lockMap(prevProps.selectionLocked);
    }
    if (this.props.dimensions.length > 0) {
      let stateIsNotSynced = false;
      if (prevProps.dimensions !== this.props.dimensions) {
        const productInfo = {
          width: this.props.dimensions[0],
          height: this.props.dimensions[1],
          mapScale: this.props.dimensions[2],
        };
        this.setState({ productInfo });
        if (this.areaRef.current !== null) {
          this.setState({ stateIsNotSynced: true });
          this.areaRef.current.redraw(this.state.viewport);
          stateIsNotSynced = this.props.zoomAreaPolygon === undefined ? false : true;
        }
      }

      // PRIMARY CASE 1: We have already drawn the area ones, and now check how to handle re-drawing it.
      if (
        prevProps.zoomAreaPolygon !== null &&
        this.props.zoomAreaPolygon !== undefined &&
        prevProps.zoomAreaPolygon !== undefined &&
        (this.state.stateIsNotSynced || stateIsNotSynced)
      ) {
        /* Note: using num.toFixed(2) here cause our Area selector returns a slightly different zoomAreaPolygon every time it's calculated.
				the diffirence is so tiny that it can be ignored using num.toFixed(2) (It rounds decimals to 2 from many) */
        //if (stateIsNotSynced) {
        if (
          // CASE 1: Options have changed so zoomAreaPolygons are NOT the same size.
          this.state?.lastMaxZoom === this.state.viewport.zoom &&
          prevProps.zoomAreaPolygon.features[0].geometry.coordinates[0][0][0].toFixed(2) !==
            this.props.zoomAreaPolygon.features[0].geometry.coordinates[0][0][0].toFixed(2) &&
          this.state.viewport.longitude === this.props.centerCoordinates[0] &&
          this.state.viewport.latitude === this.props.centerCoordinates[1]
        ) {
          this.updateCenterCoordinates();
          this.goToSelection(true, this.props.zoomAreaPolygon);
          this.setState({ stateIsNotSynced: false, updateDepthCounter: 0 });
          stateIsNotSynced = false;
        } else if (
          // CASE 2: We are on a DIFFERENT Zoom-level AND the zoomAreaPolygons are NOT the same size.
          this.state?.lastMaxZoom !== this.state.viewport.zoom &&
          this.state.updateDepthCounter > 1 &&
          this.state.viewport.longitude === this.props.centerCoordinates[0] &&
          this.state.viewport.latitude === this.props.centerCoordinates[1] &&
          this.props.zoomAreaPolygon.features[0].geometry.coordinates[0][0][0].toFixed(2) !==
            prevProps.zoomAreaPolygon.features[0].geometry.coordinates[0][0][0].toFixed(2)
        ) {
          this.updateCenterCoordinates();
          this.goToSelection(true, this.props.zoomAreaPolygon);
          this.setState({ stateIsNotSynced: false, updateDepthCounter: 0 });
          stateIsNotSynced = false;
        } else if (
          // CASE 3: Options have changed but zoomAreaPolygons ARE the same size.
          this.state.lastMaxZoom !== this.state.viewport.zoom &&
          (this.state.stateIsNotSynced || stateIsNotSynced) &&
          //this.props.dimensions.length !== 0 &&
          this.state.updateDepthCounter > 2 &&
          this.state.viewport.longitude === this.props.centerCoordinates[0] &&
          this.state.viewport.latitude === this.props.centerCoordinates[1] &&
          prevProps.zoomAreaPolygon.features[0].geometry.coordinates[0][0][0].toFixed(2) ===
            this.props.zoomAreaPolygon.features[0].geometry.coordinates[0][0][0].toFixed(2)
        ) {
          this.updateCenterCoordinates();
          this.goToSelection(true, this.props.zoomAreaPolygon);
          this.setState({ stateIsNotSynced: false, updateDepthCounter: 0 });
          stateIsNotSynced = false;
        } else if (
          // CASE 4: We are on the SAME Zoom-level AND maxZoom is still at default 15.5.
          this.state.lastMaxZoom === this.state.viewport.zoom &&
          this.state.settings.maxZoom === 15.5
        ) {
          const settings = {
            ...this.state.settings,
            maxZoom: this.state.lastMaxZoom,
          };
          this.setState({ settings });
          //this.setState({ settings })
          stateIsNotSynced = false;
        }
        // CASE 5: None of the above work, let's update center coords and try again, MAX 3 TIMES (Hence the counter).
        else if (this.state.updateDepthCounter < 4) {
          this.setState({ updateDepthCounter: this.state.updateDepthCounter + 1 });
          this.updateCenterCoordinates();
        }
        // DEFAULT CASE: This means nothing meaningfull happened in the states in 4 updates, time to halt the loop.
        else {
          this.setState({ stateIsNotSynced: false, updateDepthCounter: 0 });
          stateIsNotSynced = false;
          //console.log("LOOP HALTED: Map.js componentDidUpdate")
        }
        //}
      }
      // PRIMARY CASE 2: We are drawing for the first time.
      else if (
        this.props.zoomAreaPolygon !== undefined &&
        (this.state.stateIsNotSynced || stateIsNotSynced)
      ) {
        this.updateCenterCoordinates();
        this.goToSelection(true);
        this.setState({ stateIsNotSynced: false, updateDepthCounter: 0 });
        stateIsNotSynced = false;
      }
    }
    if (
      prevProps.dimensions.length !== 0 &&
      this.props.dimensions.length === 0 &&
      this.state.settings.maxZoom !== 15.5
    ) {
      const settings = {
        ...this.state.settings,
        maxZoom: 15.5,
      };
      this.setState({ settings });
    }
  }

  goToSelection = (boolean, upToDateZoomAreaPolygon) => {
    if (upToDateZoomAreaPolygon) {
      const { zoom } = new WebMercatorViewport(this.state.viewport).fitBounds(
        [
          upToDateZoomAreaPolygon.features[0].geometry.coordinates[0][0],
          upToDateZoomAreaPolygon.features[0].geometry.coordinates[0][2],
        ],
        {
          padding: 20,
          //offset: [0, -100],
        }
      );

      var viewport = {
        ...this.state.viewport,
        transitionDuration: 0,
      };
      viewport = {
        ...this.state.viewport,
        zoom,
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator(),
        //transitionEasing: d3.easeCubic,
      };
      /*if (zoom === this.state.viewport.maxZoom) {
				//Tried to add this, and where id does make the area show up, it doesn't zoom into or out tto it.
				viewport.transitionDuration = 0
				this.callRedraw(newWiewport)
				const { zoom } = new WebMercatorViewport(this.state.viewport).fitBounds([upToDateZoomAreaPolygon.features[0].geometry.coordinates[0][0], upToDateZoomAreaPolygon.features[0].geometry.coordinates[0][2]], {
					padding: 20,
					//offset: [0, -100],
				})
			}*/
      const settings = {
        ...this.state.settings,
        dragPan: boolean,
        maxZoom: zoom,
      };
      this.setState({
        viewport,
        settings,
        lastMaxZoom: zoom,
      });
    } else {
      const { zoom } = new WebMercatorViewport(this.state.viewport).fitBounds(
        [
          this.props.zoomAreaPolygon.features[0].geometry.coordinates[0][0],
          this.props.zoomAreaPolygon.features[0].geometry.coordinates[0][2],
        ],
        {
          padding: 20,
          //offset: [0, -100],
        }
      );
      //If the zoom is the smae as state.zoom, should we not make a transition duration?
      if (this.state.lastMaxZoom !== this.state.viewport.zoom) {
        viewport = {
          ...this.state.viewport,
          zoom,
          transitionDuration: 1000,
          transitionInterpolator: new FlyToInterpolator(),
          //transitionEasing: d3.easeCubic,
        };
      } else {
        viewport = {
          ...this.state.viewport,
          zoom,
          transitionDuration: 0,
          //transitionEasing: d3.easeCubic,
        };
      }
      const settings = {
        ...this.state.settings,
        dragPan: boolean,
        maxZoom: zoom,
        scrollZoom: boolean,
      };

      this.setState({
        viewport,
        settings,
        lastMaxZoom: zoom,
      });
    }
    if (!boolean) {
      const lastLockViewport = {
        ...this.state.viewport,
      };
      this.setState({ lastLockViewport });
    }
  };

  flyTo = (long, lat) => {
    const firstProjection = '+proj=utm +zone=35 +ellps=GRS80 +units=m +no_defs';
    const secondProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
    const convertedLongLat = proj4(firstProjection, secondProjection, [long, lat]);

    const viewport = {
      ...this.state.viewport,
      longitude: convertedLongLat[0],
      latitude: convertedLongLat[1],
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
      zoom: 10,
    };
    this.setState({ ...this.state.productInfo, viewport });
  };

  callRedraw = (viewport) => {
    if (this.props.dimensions) {
      //console.log("We are calling redraw");
      if (this.areaRef.current !== null) {
        this.areaRef.current.redraw(viewport);
      }
    }
  };

  isOutOfMaxBounds = (latitude, longitude, maxBounds) => {
    const [[swLng, swLat], [neLng, neLat]] = maxBounds;
    const isIt = longitude < swLng || longitude > neLng || latitude < swLat || latitude > neLat;
    return isIt;
  };

  handleDelay() {
    this.setState({ ...this.state, isToggleOn: false });
    setTimeout(
      function () {
        //Start the timer
        const isToggleOn = true;
        this.setState({ isToggleOn }); //After 1 second, set render to true
      }.bind(this),
      200
    );
  }

  render() {
    return (
      <MapGL
        className={
          this.props.selectionLocked ? 'mapboxgl-map is-disaled' : 'mapboxgl-map is-active'
        }
        {...this.state.viewport}
        {...this.state.settings}
        width="100vw"
        height="100vh"
        mapStyle="mapbox://styles/karttakeskus/cktv9teib22ef18ld51v85hqz"
        onViewportChange={(viewport) => {
          if (
            !this.isOutOfMaxBounds(
              viewport.latitude,
              viewport.longitude,
              this.state.bounds // your max bounds
            )
          ) {
            if (viewport.zoom === this.state.viewport.zoom) {
              this.setState({ viewport });
              //console.log("1")
            } else if (viewport.transitionDuration === 0) {
              const newWiewport = {
                ...viewport,
                latitude: this.state.viewport.latitude,
                longitude: this.state.viewport.longitude,
              };

              this.setState({ viewport: newWiewport });
              this.callRedraw(newWiewport);
              //console.log("2")
            }
            //else{this.setState({ viewport });}
            if (viewport.transitionDuration === 300 && this.state.isToggleOn) {
              this.handleDelay();
              if (viewport.zoom < 4.9) {
                viewport.zoom = 4.9;
              } else if (
                viewport.zoom >= this.state.viewport.maxZoom &&
                this.props.dimensions.length !== 0
              ) {
                viewport.zoom = this.state.lastMaxZoom;
              }
              this.setState({ viewport });
            } else if (viewport.transitionDuration !== 0 && this.state.isToggleOn) {
              this.setState({ viewport });
              //console.log("3")
            }
          }
        }}
        //These 4 make sure we calculate and redraw the selector & relevant geoJSONs when needed.
        onTransitionStart={() => {
          if (this.state.viewport.transitionDuration === 300) {
            /*const viewport = {
							...this.state.viewport,
							maxZoom: this.state.lastMaxZoom,
						}
						this.setState({ viewport })*/
            this.updateViewportOnTransitionEnd();
          }
        }}
        onTransitionEnd={() => {
          this.updateViewportOnTransitionEnd();
        }}
        onTouchEnd={() => {
          this.updateViewportOnTransitionEnd();
        }}
        onMouseUp={() => {
          this.updateViewportOnTransitionEnd();
        }}
        //---------------
        mapboxApiAccessToken={this.props.mapboxToken}
        FlyToInterpolator={new FlyToInterpolator()}
      >
        {this.props.dimensions.length > 0 && this.state.viewport.transitionDuration < 301 && (
          <div style={this.styles.map}>
            <AreaSelector
              ref={this.areaRef}
              productInfo={this.state.productInfo}
              viewport={this.state.viewport}
              mapLocked={this.props.selectionLocked}
              previewViewport={this.props.previewViewport}
              setPreviewViewport={this.props.setPreviewViewport}
              cursorCoordinates={this.props.cursorCoordinates}
              setCursorCoordinates={this.props.setCursorCoordinates}
              setPreviewGeoJSON={this.props.setPreviewGeoJSON}
              setZoomAreaPolygon={this.props.setZoomAreaPolygon}
              setScrollLimitPolygon={this.props.setScrollLimitPolygon}
              setTargetingRectangle={this.props.setTargetingRectangle}
              targetingRectangle={this.props.targetingRectangle}
              mapboxToken={this.props.mapboxToken}
            />
          </div>
        )}
        <div className={this.props.selectionLocked ? 'zoom-wrap' : 'zoom-wrap is-active'}>
          <NavigationControl
            showCompass={false}
            style={this.state.isToggleOn ? { pointerEvents: 'all' } : { pointerEvents: 'all' }}
          />
        </div>
        <Search flyTo={this.flyTo} isClickable={!this.props.selectionLocked} />
      </MapGL>
    );
  }
}

Map.propTypes = {
  centerCoordinates: PropTypes.arrayOf(PropTypes.number),
  dimensions: PropTypes.arrayOf(PropTypes.number),
  selectionLocked: PropTypes.bool,

  zoomAreaPolygon: PropTypes.shape({
    type: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        geometry: PropTypes.shape({
          coordinates: PropTypes.array,
        }),
      })
    ),
  }),
};

export default Map;
