import React, { useState } from 'react';
import Dialog from './Dialog';
import { ReactComponent as ChevronLeftIcon } from '../assets/icons/icon-chevron-left.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close-small.svg';
import { ReactComponent as TooltipIcon } from '../assets/icons/icon-tooltip.svg';
import PropTypes from 'prop-types';

const SelectProduct = (props) => {
  const [value, setValue] = useState('');
  const [name, setName] = useState('');
  const [isActive, setActive] = useState(false);
  const [openDialog, setOpenDialog] = useState('');

  // Handle the value change
  const handleChange = (newValue, name) => {
    if (newValue.id !== value.id) {
      props.setAttributes([]);
      props.setFilters([]);
      props.setVariations([]);
    }

    setActive(!isActive);
    setName(name);
    setValue(newValue);
    props.setSelectedProduct(newValue);
  };

  const handleClick = () => {
    setActive(!isActive);
  };

  // Handle input clearing
  const handleClear = () => {
    setValue('');
    setName('');
    props.setAttributes([]);
    props.setFilters([]);
    props.setVariations([]);
    props.setSelectedProduct({});
  };

  // Make a list of products as buttons
  const options = props.options
    ? props.options.map((item, i) => {
        return (
          <li key={i} className={item.id === value.id ? 'is-active' : ''}>
            <button onClick={() => handleChange(item, item.name)}>{item.name}</button>
            {item.description && (
              <button
                className="tooltip"
                data-open={`dialog-${item.id}`}
                onClick={() => setOpenDialog(`dialog-${item.id}`)}
              >
                <TooltipIcon />
              </button>
            )}
          </li>
        );
      })
    : [];

  // Make a list of dialogs for product tooltips
  const dialogs = props.options
    ? props.options.map((item, i) => {
        if (item.description) {
          return (
            <Dialog
              key={i}
              id={`dialog-${item.id}`}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              title={item.name}
              html={item.description}
            />
          );
        } else return null;
      })
    : [];

  return (
    <>
      <div
        className={isActive ? 'close-select is-active' : 'close-select'}
        onClick={() => handleClick()}
      />
      <div className={props.isClickable ? 'select is-active' : 'select is-disabled'}>
        <div className="select__label-wrap">
          <div className="select__label">
            <p>{props.label}</p>
          </div>
          {props.tooltip !== '' && (
            <button className="tooltip" onClick={() => setOpenDialog('attr-dialog')}>
              <TooltipIcon />
            </button>
          )}
        </div>

        <div className="select__input-wrap">
          <button className="select__input" onClick={() => handleClick()}>
            {!value ? props.placeholder : name}
            <ChevronLeftIcon />
          </button>
          {value !== '' && (
            <button className="select__input-clear" onClick={() => handleClear()}>
              <CloseIcon />
            </button>
          )}
          <ul className={isActive ? 'select__input-options is-active' : 'select__input-options'}>
            {options}
          </ul>
        </div>
      </div>
      <Dialog
        id="attr-dialog"
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={props.label}
        html={props.tooltip}
      />
      {dialogs}
    </>
  );
};

SelectProduct.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,

  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default SelectProduct;
