import ReactDOM from 'react-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Dialog = (props) => {
  const { t } = useTranslation('paraskartta');

  const el = (
    <div id={props.id} className={props.openDialog === props.id ? 'dialog is-active' : 'dialog'}>
      <div className="dialog__background" onClick={() => props.setOpenDialog('')} />
      <div className="dialog__box">
        {props.title && <h3 className="dialog__title">{props.title}</h3>}
        {props.content && <div className="dialog__content">{props.content}</div>}
        {props.html && (
          <div className="dialog__content" dangerouslySetInnerHTML={{ __html: props.html }} />
        )}
        <div className="dialog__buttons">
          <button
            onClick={() => {
              props.setOpenDialog('');
            }}
          >
            {t('dialog.close')}
          </button>
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(el, document.getElementById('portal'));
};

Dialog.propTypes = {
  id: PropTypes.string,
  openDialog: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  html: PropTypes.string,
};

export default Dialog;
