import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

const Checkbox = (props) => {
  const [value, setValue] = useState();
  const defaultValueIndex = useMemo(
    () => props.options.findIndex((option) => option.includes('-')),
    [props.options]
  );
  const otherValueIndex = defaultValueIndex === 0 ? 1 : 0;
  // Handle the value and filters change between two values
  const handleChange = useCallback(
    (checked) => {
      const newFilters = { ...props.filters };
      const newValue = checked ? props.options[otherValueIndex] : props.options[defaultValueIndex];

      setValue(newValue);
      newFilters[props.id] = newValue;
      props.setFilters(newFilters);
    },
    [props, value]
  );

  // Set default value and add it to filters on first render
  useEffect(() => {
    if (!value && defaultValueIndex >= 0) {
      handleChange(false);
    }
  }, [props, handleChange, value]);

  const checkboxId = `checkbox-${props.options[defaultValueIndex].replace(/\s/g, '')}`;

  const checkbox = (
    <div className="checkbox" key={props.id}>
      <label htmlFor={checkboxId}>
        <input
          id={checkboxId}
          type="checkbox"
          checked={value !== props.options[defaultValueIndex]}
          onChange={(e) => handleChange(e.currentTarget.checked)}
        />
        <span>{props.options[otherValueIndex]}</span>
      </label>
    </div>
  );

  return (
    <>
      <div className="checkbox-wrap">{checkbox}</div>
    </>
  );
};

Checkbox.propTypes = {
  filters: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  options: PropTypes.array,
};

export default Checkbox;
