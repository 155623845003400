import React, { useEffect, useState } from 'react';
import './sass/styles.css';
import Map from './components/Map.js';
import Header from './components/Header';
import Options from './components/Options';
import { useTranslation } from 'react-i18next';
import { deviceType, isMobileSafari, isMobileChrome, isAndroid, isIOS } from 'react-device-detect';

const App = () => {
  const MAPBOX_TOKEN =
    'pk.eyJ1Ijoia2FydHRha2Vza3VzIiwiYSI6ImNrbWRoams5YjBjcmgyb3A1MTA4aTNsNjgifQ.3WHVzd5oSRSENVSPQGYdpg';

  const { t, i18n } = useTranslation('paraskartta');
  const [language, setLanguage] = useState(i18n.language);
  const [dimensions, setDimensions] = useState([]);
  const [targetingRectangle, setTargetingRectangle] = useState({
    width: 0,
    height: 0,
    boxShadow: '0 0 0 100vmax rgba(0,0,0,.2)',
  });
  const [centerCoordinates, setCenterCoordinates] = useState([]);
  const [selectionLocked, setSelectionLocked] = useState(false);
  const [previewViewport, setPreviewViewport] = useState({
    longitude: 22.266606485609433,
    latitude: 60.451695872307134,
  });
  const [cursorCoordinates, setCursorCoordinates] = useState([]);
  //preview JSON values:
  const [previewGeoJSON, setPreviewGeoJSON] = useState();
  const [zoomAreaPolygon, setZoomAreaPolygon] = useState();
  const [scrollLimitPolygon, setScrollLimitPolygon] = useState();

  useEffect(() => {
    setPreviewViewport((previewViewport) => {
      return {
        ...previewViewport,
        longitude: centerCoordinates[0],
        latitude: centerCoordinates[1],
      };
    });
  }, [centerCoordinates]);

  return (
    <div className="app">
      <Header setLanguage={setLanguage} />
      {deviceType === 'mobile' ||
      deviceType === 'tablet' ||
      isMobileSafari === true ||
      isMobileChrome === true ||
      isIOS === true ||
      isAndroid === true ? (
        <div className="notice">
          <span aria-hidden="true">
            <p>i</p>
          </span>
          <h2>{t('app.notice')}</h2>
        </div>
      ) : (
        <div className="map-container">
          <Map
            centerCoordinates={centerCoordinates}
            setCenterCoordinates={setCenterCoordinates}
            dimensions={dimensions}
            selectionLocked={selectionLocked}
            previewViewport={previewViewport}
            setPreviewViewport={setPreviewViewport}
            cursorCoordinates={cursorCoordinates}
            setCursorCoordinates={setCursorCoordinates}
            setPreviewGeoJSON={setPreviewGeoJSON}
            zoomAreaPolygon={zoomAreaPolygon}
            setZoomAreaPolygon={setZoomAreaPolygon}
            setScrollLimitPolygon={setScrollLimitPolygon}
            targetingRectangle={targetingRectangle}
            setTargetingRectangle={setTargetingRectangle}
            mapboxToken={MAPBOX_TOKEN}
          />
          <Options
            centerCoordinates={centerCoordinates}
            setDimensions={setDimensions}
            setSelectionLocked={setSelectionLocked}
            dimensions={dimensions}
            previewViewport={previewViewport}
            setPreviewViewport={setPreviewViewport}
            cursorCoordinates={cursorCoordinates}
            setCursorCoordinates={setCursorCoordinates}
            previewGeoJSON={previewGeoJSON}
            scrollLimitPolygon={scrollLimitPolygon}
            language={language}
            mapboxToken={MAPBOX_TOKEN}
          />
        </div>
      )}
    </div>
  );
};

export default App;
