import * as React from 'react';
import { Component } from 'react';
import { WebMercatorViewport } from 'react-map-gl';
import * as turf from '@turf/turf';
import PreviewControl from './PreviewControl';
import { Layer, Source } from 'react-map-gl';
import PropTypes from 'prop-types';

class AreaSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetPlygon: '',
      source: {
        type: 'FeatureCollection',
        features: [],
      },
      layer: {
        id: 'preview-data',
        type: 'fill',
        source: 'previewArea',
        paint: {
          'fill-color': '#088',
          'fill-opacity': 0.8,
        },
      },
    };
  }

  componentDidMount() {
    //console.log("this is the viewport: " + this.props.viewport);
    this.redraw(this.props.viewport);
    if (this.props.mapLocked === true) {
      this.props.setTargetingRectangle({
        ...this.props.targetingRectangle,
        boxShadow: '0 0 0 100vmax rgba(0,0,0,.8)',
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productInfo !== this.props.productInfo) {
      this.redraw(this.props.viewport);
    }
    if (prevProps.mapLocked !== this.props.mapLocked) {
      if (this.props.mapLocked === true) {
        this.props.setTargetingRectangle({
          ...this.props.targetingRectangle,
          boxShadow: '0 0 0 100vmax rgba(0,0,0,.8)',
        });

        //this.redraw(this.props.viewport);
      } else {
        this.props.setTargetingRectangle({
          ...this.props.targetingRectangle,
          boxShadow: '0 0 0 100vmax rgba(0,0,0,.2)',
        });
      }
    }
  }

  getTargetAreaCorners(topCoords, rightCoords, leftCoords, bottomCoords, MercatorViewport) {
    //calculate and save the screen coordinates of the selected area
    const topLeftPixel = MercatorViewport.project(
      [leftCoords.geometry.coordinates[0], topCoords.geometry.coordinates[1]],
      {},
      true
    );

    const bottomRightPixel = MercatorViewport.project(
      [rightCoords.geometry.coordinates[0], bottomCoords.geometry.coordinates[1]],
      {},
      true
    );
    const bottomLeftPixel = MercatorViewport.project(
      [leftCoords.geometry.coordinates[0], bottomCoords.geometry.coordinates[1]],
      {},
      true
    );
    const width = Math.sqrt(
      Math.pow(bottomLeftPixel[0] - bottomRightPixel[0], 2) +
        Math.pow(bottomLeftPixel[1] - bottomRightPixel[1], 2)
    );
    const height = Math.sqrt(
      Math.pow(topLeftPixel[1] - bottomLeftPixel[1], 2) +
        Math.pow(topLeftPixel[0] - bottomLeftPixel[0], 2)
    );

    this.props.setTargetingRectangle({
      ...this.props.targetingRectangle,
      width: width,
      height: height,
    });

    //now using this width and height, we can calculate even a tilted geoJSON:
    if (width > 0) {
      this.getPreviewGeoJSON(width, height, MercatorViewport);
    }
    //console.log("Pixel Cords: " + topRightPixel + ", " + bottomLeftPixel);
  }

  getPreviewGeoJSON(width, height, MercatorViewport) {
    const topRightCoords = turf.point(
      MercatorViewport.unproject(
        [window.innerWidth / 2 + width / 2, window.innerHeight / 2 - height / 2],
        {},
        true
      )
    );
    const bottomLeftCoords = turf.point(
      MercatorViewport.unproject(
        [window.innerWidth / 2 - width / 2, window.innerHeight / 2 + height / 2],
        {},
        true
      )
    );
    const topLeftCoords = turf.point(
      MercatorViewport.unproject(
        [window.innerWidth / 2 - width / 2, window.innerHeight / 2 - height / 2],
        {},
        true
      )
    );
    const bottomRightCoords = turf.point(
      MercatorViewport.unproject(
        [window.innerWidth / 2 + width / 2, window.innerHeight / 2 + height / 2],
        {},
        true
      )
    );

    const polygon = turf.polygon(
      [
        [
          [topRightCoords.geometry.coordinates[0], topRightCoords.geometry.coordinates[1]],
          [bottomRightCoords.geometry.coordinates[0], bottomRightCoords.geometry.coordinates[1]],
          [bottomLeftCoords.geometry.coordinates[0], bottomLeftCoords.geometry.coordinates[1]],
          [topLeftCoords.geometry.coordinates[0], topLeftCoords.geometry.coordinates[1]],
          [topRightCoords.geometry.coordinates[0], topRightCoords.geometry.coordinates[1]],
        ],
      ],
      { name: 'targetArea' }
    );

    //we enlargen the target area polygon for the bacground of the mask.
    //this makes a new polygon 1.2 times the size of the one given.

    //this is were we auto zoom on lock in.
    var biggerPolygon = turf.transformScale(polygon, 1.3);
    //this is used in preview window as bounding box
    var scrollLimitPolygon = turf.transformScale(polygon, 1.8);
    //this is used in preview as mask.
    var polygonMaskPolygon = turf.transformScale(polygon, 3);

    //then we create the mask from the difference of the targeting area polygon and it's enlagened version.
    var polygonMask = turf.difference(polygonMaskPolygon, polygon);

    //this is where we actually send the features to App.js and from there to PreviewWindow.js
    this.props.setPreviewGeoJSON({
      type: 'FeatureCollection',
      features: [{ type: 'Feature', geometry: polygonMask.geometry }],
    });
    this.props.setZoomAreaPolygon({
      type: 'FeatureCollection',
      features: [{ type: 'Feature', geometry: biggerPolygon.geometry }],
    });
    this.props.setScrollLimitPolygon({
      type: 'FeatureCollection',
      features: [{ type: 'Feature', geometry: scrollLimitPolygon.geometry }],
    });
  }

  redraw(viewport) {
    //console.log("redraw")
    if (this.props.productInfo.width !== undefined) {
      //some basic trigonometry to get required props for Turf.destination
      const MercatorViewport = new WebMercatorViewport(viewport);
      //console.log(MercatorViewport)
      let X = (this.props.productInfo.width / 2) * this.props.productInfo.mapScale;
      let Y = (this.props.productInfo.height / 2) * this.props.productInfo.mapScale;

      const point = turf.point(
        MercatorViewport.unproject([window.innerWidth / 2, window.innerHeight / 2], {}, true)
      );

      //------------------------------------------------------------------------
      //Alternative way of calculating the bounding box.
      //Instead of counting the corners, we count the distance to the edges.
      //This prevents the warping of the target area height and width caused by Earth being round.
      X = X / 10000;
      Y = Y / 10000;

      let topCoords = turf.destination(point, Y, 0);
      let rightCoords = turf.destination(point, X, 90);
      let leftCoords = turf.destination(point, X, -90);
      let bottomCoords = turf.destination(point, Y, 180);

      this.getTargetAreaCorners(topCoords, rightCoords, leftCoords, bottomCoords, MercatorViewport);
    }
  }
  render() {
    return (
      <div className="area-selector" style={this.props.targetingRectangle}>
        <Source id="previewArea" type="geojson" data={this.state.source}>
          <Layer {...this.state.layer} />
        </Source>
        {this.props.targetingRectangle.width !== 0 && (
          <PreviewControl
            zoom={this.props.zoom}
            width={`${this.props.targetingRectangle.width}px`}
            height={`${this.props.targetingRectangle.height}px`}
            mapLocked={this.props.mapLocked}
            previewViewport={this.props.previewViewport}
            setPreviewViewport={this.props.setPreviewViewport}
            viewport={this.props.viewport}
            cursorCoordinates={this.props.cursorCoordinates}
            setCursorCoordinates={this.props.setCursorCoordinates}
            targetPlygon={this.state.targetPlygon}
            mapboxToken={this.props.mapboxToken}
          />
        )}
      </div>
    );
  }
}

AreaSelector.propTypes = {
  mapLocked: PropTypes.bool,

  productInfo: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    mapScale: PropTypes.number,
  }),

  viewport: PropTypes.shape({
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    zoom: PropTypes.number,
    bearing: PropTypes.number,
    pitch: PropTypes.number,
    transitionDuration: PropTypes.number,
  }),

  targetingRectangle: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    boxShadow: PropTypes.string,
  }),

  mapboxToken: PropTypes.string.isRequired,
};

export default AreaSelector;
