import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MagnifierIcon } from '../assets/icons/icon-magnifier.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close.svg';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import { ReactComponent as ChevronLeftIcon } from '../assets/icons/icon-chevron-left.svg';
import PropTypes from 'prop-types';

const Search = (props) => {
  const [search, setSearch] = useState('');
  const [results, setResults] = useState([]);
  const [resultsActive, setResultsActive] = useState(false);
  const { t } = useTranslation('paraskartta');
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [municipalities, setMunicipalities] = useState([]);
  const [lastResultIndex, setLastResultIndex] = useState(9);
  const MML_TOKEN = '819b7a52-c0b4-4091-bf47-e4aadac82030';

  // Fetch all municipalities to later tying them with the search results
  useEffect(() => {
    axios
      .get(
        `https://avoin-paikkatieto.maanmittauslaitos.fi/geographic-names/features/v1/collections/placenames/items?crs=http://www.opengis.net/def/crs/EPSG/0/3067&placeType=4010125&language=fin&api-key=${MML_TOKEN}`
      )
      .then((response) => {
        if (response.data.features.length > 0) {
          const result = response.data.features.map((feature) => {
            return {
              spelling: feature.properties.spelling,
              municipality: feature.properties.municipality,
            };
          });
          setMunicipalities(result);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // Get the search results from MML API
  const fetchPlaces = useCallback(() => {
    axios
      .get(
        `https://avoin-paikkatieto.maanmittauslaitos.fi/geographic-names/features/v1/collections/placenames/items?crs=http://www.opengis.net/def/crs/EPSG/0/3067&spelling_case_insensitive=${search}*&placeType=1010105,
1010110,1010120,1010125,1010130,1010205,1010210,1010305,1010405,1010410,1020105,1020205,1999999,2010105,2010110,2010205,2010210,2010215,2010220,2010225,2010230,2020105,2020110,2020115,2030105,3010105,3010110,3020105,4010125,4060605,5010105,5010110,5010115,5999905,5999910,6010305&api-key=${MML_TOKEN}`
      )
      .then((response) => {
        if (response.data.features.length > 0) {
          const result = response.data.features.map((feature) => {
            return {
              id: feature.id,
              geometry: feature.geometry,
              properties: {
                spelling: feature.properties.spelling,
                municipality: feature.properties.municipality,
                placeType: feature.properties.placeType,
              },
            };
          });
          // Sort results by putting municipalities, cities and villages first
          const sortedResult = [
            ...result.filter((item) => {
              return (
                item.properties.spelling.toLowerCase() === search.toLowerCase() &&
                item.properties.placeType === 4010125
              );
            }),
            ...result.filter((item) => {
              return item.properties.spelling.toLowerCase() === search.toLowerCase();
            }),
            ...result.filter((item) => {
              return item.properties.placeType === 4010125;
            }),
            ...result.filter((item) => {
              return item.properties.placeType === 3010105;
            }),
            ...result.filter((item) => {
              return item.properties.placeType === 3010110;
            }),
            ...result.filter((item) => {
              return item.properties.placeType === 3020105;
            }),
            ...result.filter((item) => {
              return (
                item.properties.placeType !== 4010125 &&
                item.properties.placeType !== 3010105 &&
                item.properties.placeType !== 3010110 &&
                item.properties.placeType !== 3020105
              );
            }),
          ];
          // Remove duplicates
          const uniqueResult = [];
          sortedResult.forEach((item) => {
            if (
              uniqueResult.findIndex(
                (x) =>
                  x.properties.spelling === item.properties.spelling &&
                  x.properties.municipality === item.properties.municipality &&
                  x.geometry.coordinates[0] === item.geometry.coordinates[0] &&
                  x.geometry.coordinates[1] === item.geometry.coordinates[1]
              ) === -1
            ) {
              uniqueResult.push(item);
            }
          });

          setResults(uniqueResult);
          setIsLoading(false);
        } else {
          setNoResults(true);
          setIsLoading(false);
          setResults([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [search]);

  // Set short timeout for api call after typing has ended
  useEffect(() => {
    if (search.length >= 2) {
      setLastResultIndex(9);
      setIsLoading(true);
      const timer = setTimeout(() => {
        fetchPlaces();
      }, 250);
      return () => {
        clearTimeout(timer);
      };
    } else {
      setResults([]);
      setIsLoading(false);
    }
  }, [search, fetchPlaces]);

  // Map a list of results in buttons
  const resultList = results
    .map((item, i) => {
      const duplicates = results.filter((x) => {
        return x.properties.spelling === item.properties.spelling;
      });
      const municipalityIndex = municipalities.findIndex(
        (x) => x.municipality === item.properties.municipality
      );
      return (
        <li key={i} className="result-item">
          <button
            onClick={() => {
              if (!isLoading) {
                props.flyTo(item.geometry.coordinates[0], item.geometry.coordinates[1]);
                setResultsActive(false);
                setSearch('');
              }
            }}
          >
            {item.properties.spelling}
            {municipalityIndex > -1 &&
              duplicates.length > 1 &&
              item.properties.placeType !== 4010125 &&
              `, ${municipalities[municipalityIndex].spelling}`}
          </button>
        </li>
      );
    })
    .filter((item, i) => {
      if (i <= lastResultIndex && i > lastResultIndex - 10) {
        return item;
      } else return null;
    });

  return (
    <>
      <div
        className={
          (results.length > 0 && resultsActive) || noResults
            ? 'close-search is-active'
            : 'close-search'
        }
        onClick={() => {
          setSearch('');
          setNoResults(false);
        }}
      />
      <div className={props.isClickable ? 'search-wrap is-active' : 'search-wrap is-disabled'}>
        <div className={props.isClickable ? 'search is-active' : 'search is-disabled'}>
          <label htmlFor="search" className="search__label">
            {t('search.label')}
          </label>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (results.length > 0) {
                setSearch('');
                props.flyTo(results[0].geometry.coordinates[0], results[0].geometry.coordinates[1]);
                setResultsActive(false);
                document.activeElement.blur();
              }
            }}
          >
            <input
              id="search"
              role="search"
              autoComplete="off"
              onClick={() => results && setResultsActive(true)}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                setNoResults(false);
              }}
              className="search__input"
              type="text"
              placeholder={t('search.placeholder')}
            />
          </form>
          <MagnifierIcon className="search__icon" />
          {search !== '' && !isLoading && (
            <button
              onClick={() => {
                setSearch('');
                setNoResults(false);
              }}
              className="search__clear"
            >
              <CloseIcon />
            </button>
          )}
          {isLoading && (
            <div className="search__spinner">
              <CircularProgress className="spinner__icon" />
            </div>
          )}
          <div
            className={
              (results.length > 0 && resultsActive) || noResults
                ? 'search__results is-active'
                : 'search__results'
            }
          >
            <p
              className={
                results.length > 0 ? 'search__results-count has-results' : 'search__results-count'
              }
            >
              {results.length > 0 ? (
                <>
                  {results.length} {t('search.results_count')}
                </>
              ) : (
                t('search.no_results')
              )}
            </p>
            <ul>
              {results.length > 0 && (
                <>
                  {resultList}
                  {results.length > 10 && (
                    <div className="search__pagination">
                      {lastResultIndex > 9 && (
                        <button onClick={() => setLastResultIndex(lastResultIndex - 10)}>
                          <ChevronLeftIcon />
                          {t('search.prev_page')}
                        </button>
                      )}
                      {results.length > 10 && lastResultIndex < results.length - 1 && (
                        <button
                          className="next"
                          onClick={() => setLastResultIndex(lastResultIndex + 10)}
                        >
                          {t('search.next_page')}
                          <ChevronLeftIcon />
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

Search.propTypes = {
  isClickable: PropTypes.bool,
};

export default Search;
