import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from './Select';
import SelectProduct from './SelectProduct';
import { ReactComponent as ShoppingCartIcon } from '../assets/icons/icon-shopping-cart.svg';
import { ReactComponent as LockedIcon } from '../assets/icons/icon-locked.svg';
import { ReactComponent as LockOpenIcon } from '../assets/icons/icon-lock-open.svg';
import { ReactComponent as ChevronLeftIcon } from '../assets/icons/icon-chevron-left.svg';
import axios from 'axios';
import _ from 'lodash';
import PreviewWindow from './PreviewWindow';
import proj4 from 'proj4';
import Dialog from './Dialog';
import Checkbox from './Checkbox';
import { CircularProgress } from '@material-ui/core';
import TitleField from './TitleField';

// Added for local development support
const apiProtocol = 'https';
const apiHost = () => {
  let domain = window.location.hostname.split('.');
  if (domain[0] === 'app') {
    domain = domain.slice(1);
  }
  domain = domain.join('.');
  if (domain === 'localhost') {
    domain = 'omakartta.staging.taiste.fi';
  }
  return `api.${domain}`;
};

const webshopHost = () => {
  let domain = window.location.hostname;
  if (domain.indexOf('tapio.fi') >= 0) {
    return 'www.tapionverkkokauppa.fi';
  }

  return 'tapio-webshop-staging.frantic.ai';
};

const Options = (props) => {
  const {
    setDimensions,
    mapboxToken,
    previewViewport,
    setPreviewViewport,
    centerCoordinates,
    cursorCoordinates,
    setCursorCoordinates,
    previewGeoJSON,
    scrollLimitPolygon,
    language,
    setSelectionLocked,
  } = props;
  const { t } = useTranslation('paraskartta');
  const [isOptionsActive, setIsOptionsActive] = useState(true);
  const [isPreviewActive, setIsPreviewActive] = useState(true);
  const [isMapLocked, setMapLocked] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [variations, setVariations] = useState([]);
  const [filters, setFilters] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [filteredVariations, setFilteredVariations] = useState([]);
  const [openDialog, setOpenDialog] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [mapTitles, setMapTitles] = useState([]);

  // Fetch the variations from the backend when product is selected
  const getVariations = useCallback((selectedProductId) => {
    const host = apiHost();
    setIsLoading(true);
    axios
      .get(`${apiProtocol}://${host}/api/products/${selectedProductId}/variations/`)
      .then((response) => {
        const newVariations = response.data.map((item) => {
          const newObj = _.pick(item, ['id', 'attributes', 'regular_price', 'omakartta']);
          newObj.descriptions = newObj.attributes.map(({ option, description }) => {
            return { option, description };
          });
          newObj.attributes = newObj.attributes.map(({ option }) => {
            return option;
          });
          return newObj;
        });
        setVariations(newVariations);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (selectedProduct.id) {
      getVariations(selectedProduct.id);
    }
  }, [getVariations, selectedProduct]);

  const selectTypeAttributes = useMemo(() => {
    return attributes
      .filter(({ visible, type }) => {
        return visible && (type === null || type === '' || type === 'select');
      })
      .map(({ options, ...item }) => ({
          ...item,
          options: options
                    .map((opt) => opt.option)
                    .sort((a, b) => (b.menu_order ?? 1000) - (a.menu_order ?? 1000))
        }));
  }, [attributes]);

  useEffect(() => {
    const titles = attributes
      .filter(({ visible, type }) => visible && type === 'text')
      .flatMap(({ slug }) =>
        selectedProduct?.omakartta[slug.substring('pa_omakartta_'.length)].map((item) => ({
          ...item,
          value: '',
        }))
      );
    setMapTitles(titles);
  }, [attributes]);

  // Create product attributes
  const getAttributes = useCallback((selectedProduct, variations) => {
    if (selectedProduct.id) {
      setIsLoading(true);
      const attrs = selectedProduct?.attributes;
      // Filter attribute options that do not exist in the variations
      if (attrs.length > 0) {
        const filteredAttrs = attrs?.map((attr) => {
          attr.options = attr.options.filter((option) => {
            return (
              !attr.variation ||
              variations.some((variation) => {
                return variation?.attributes?.find((x) => x === option.option) !== undefined;
              })
            );
          });
          return attr;
        });
        setAttributes(filteredAttrs);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (variations.length > 0) {
      getAttributes(selectedProduct, variations);
    }
  }, [getAttributes, variations, selectedProduct]);

  // Filter variations with given filters
  useEffect(() => {
    if (variations.length > 0) {
      const filtered = variations.filter((variation) => {
        return (
          Object.entries(filters)
            .filter(([k]) => selectedProduct.attributes.find((x) => x.id === k)?.variation)
            // eslint-disable-next-line no-unused-vars
            .every(([_, filter]) => {
              return variation.attributes.includes(filter);
            })
        );
      });
      setFilteredVariations(filtered);
    } else {
      setFilteredVariations([]);
    }
  }, [filters, variations]);

  const hasCompleteInformation = useMemo(
    () => filteredVariations.length === 1,
    [filteredVariations]
  );

  // Set the chosen dimensions from filtered variations for other components to use
  useEffect(() => {
    if (hasCompleteInformation) {
      const variation = filteredVariations[0];
      let width = null;
      let height = null;
      let scale = null;
      if (variation.omakartta.print_orientation === 'landscape') {
        width = variation.omakartta.print_size[0] / 100;
        height = variation.omakartta.print_size[1] / 100;
        scale = variation.omakartta.map_scale;
      } else {
        width = variation.omakartta.print_size[1] / 100;
        height = variation.omakartta.print_size[0] / 100;
        scale = variation.omakartta.map_scale;
      }

      if (variation.omakartta.print_margin && variation.omakartta.print_margin.length > 0) {
        width -= variation.omakartta.print_margin[0] / 100;
        height -= variation.omakartta.print_margin[1] / 100;
      }

      setDimensions([width, height, scale]);
    } else {
      setDimensions([]);
    }
  }, [hasCompleteInformation, filteredVariations, setDimensions]);

  // Get the products from backend on first render OR when language is changed
  const getProducts = useCallback((language) => {
    setIsLoading(true);
    const host = apiHost();
    axios
      .get(`${apiProtocol}://${host}/api/products/?lang=${language}`)
      .then((response) => {
        const sortedProducts = response.data.sort((a, b) => {
          const attributeA = a.attributes[0].options.find((x) => x.option == a.name) ?? {menu_order: 1000};
          const attributeB = b.attributes[0].options.find((x) => x.option == b.name) ?? {menu_order: 1000};
          return (
            attributeA?.menu_order -
            attributeB?.menu_order
          );
        });
        setProducts(sortedProducts);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, []);

  // Fetch products on first render
  useEffect(() => {
    getProducts(language);
  }, [getProducts, language]);

  // Handle Options UI logic for locking the area selection
  const setMapLock = (boolean) => {
    setMapLocked(boolean);
    setSelectionLocked(boolean);
  };

  const createOmakarttaUrlComponent = () => {
    const firstProjection = '+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs';
    const secondProjection =
      '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs';
    const convertedLongLat = proj4(firstProjection, secondProjection, centerCoordinates);
    const coordinates = convertedLongLat.toString().split(',');

    const props = {
      map_center: coordinates,
    };

    // non-variant information goes to omakartta props
    const slugs = Object.entries(filters)
      .filter(([key]) => !selectedProduct.attributes.find((x) => x.id === key).variation)
      .map(([k, value]) => {
        const attr = selectedProduct.attributes.find((x) => x.id === k);
        return attr.options.find((x) => x.option === value).slug;
      });

    if (slugs.length > 0) {
      props.slugs = slugs;
    }

    if (mapTitles.length > 0) {
      props.subtitles = Object.fromEntries(
        mapTitles.filter((item) => item.value !== '').map((item) => [item.id, item.value])
      );
    }

    return encodeURIComponent(JSON.stringify(props));
  };

  // Redirect browser to Tapio webshop shopping cart with desired product variation and converted map details in query string params
  const addToCart = () => {
    if (filteredVariations[0]) {
      const webshop_host = webshopHost();
      const url = `${apiProtocol}://${webshop_host}/verkkokauppa/ostoskori/?add-to-cart=${
        filteredVariations[0].id
      }&omakartta=${createOmakarttaUrlComponent()}`;
      window.location.href = url;
    }
  };

  return (
    <>
      <div
        className={`options ${isOptionsActive ? 'is-active' : ''} ${
          selectTypeAttributes.length > 0 ? 'has-attributes' : ''
        }`}
      >
        <button
          className={isOptionsActive ? 'options__toggle is-active' : 'options__toggle'}
          onClick={() => setIsOptionsActive(!isOptionsActive)}
        >
          <ChevronLeftIcon />
        </button>

        <div id="select-container"></div>

        <div className="options-wrap">
          {!isMapLocked ? (
            <p className="options__title">{t('options.title')}</p>
          ) : (
            <p className="options__title">{t('options.title_locked')}</p>
          )}

          <SelectProduct
            isClickable={!isMapLocked}
            setSelectedProduct={setSelectedProduct}
            setFilters={setFilters}
            setAttributes={setAttributes}
            options={products}
            label={!isMapLocked ? t('options.products_label') : t('options.products_label_locked')}
            placeholder={t('options.select_placeholder')}
            setVariations={setVariations}
            tooltip={t('options.products_label_tooltip')}
          />

          {selectTypeAttributes.map((item, i) => {
            if (['pa_omakartta_layers', 'pa_omakartta_map_layers'].includes(item.slug)) {
              return (
                <Checkbox
                  id={item.id}
                  isClickable={!isMapLocked}
                  setFilters={setFilters}
                  filters={filters}
                  filteredVariations={filteredVariations}
                  variations={variations}
                  key={i}
                  options={item.options}
                />
              );
            } else {
              return (
                <Select
                  id={item.id}
                  isClickable={!isMapLocked}
                  setFilters={setFilters}
                  filters={filters}
                  filteredVariations={filteredVariations}
                  variations={variations}
                  key={i}
                  options={item.options}
                  placeholder={t('options.select_placeholder')}
                  label={item.name}
                  tooltip={item.description}
                  isVariation={item.variation}
                />
              );
            }
          })}

          {mapTitles?.length > 0 &&
            mapTitles.map((item, i) => {
              return <TitleField key={i} index={i} field={item} setMapTitles={setMapTitles} />;
            })}

          <div className="options__price">
            <p>
              <span>{t('options.price')}:</span>
              {hasCompleteInformation ? `${filteredVariations[0].regular_price}€` : '-'}
            </p>
          </div>

          {isLoading && (
            <div className="options__spinner">
              <CircularProgress className="spinner__icon" />
            </div>
          )}

          {isMapLocked ? (
            <button
              style={{ marginTop: '24px' }}
              className="button button--default button--option"
              onClick={() => setMapLock(false)}
            >
              <LockOpenIcon />
              {t('options.unlock_map')}
            </button>
          ) : hasCompleteInformation ? (
            <button
              style={{ marginTop: '24px' }}
              className="button button--hollow button--option"
              onClick={() => setMapLock(true)}
            >
              <LockedIcon />
              {t('options.lock_map')}
            </button>
          ) : (
            <button
              style={{ marginTop: '24px' }}
              className="button button--hollow button--option"
              disabled
            >
              <LockedIcon />
              {t('options.lock_map')}
              <span className="button__inner" onClick={() => setOpenDialog('lock-dialog')} />
            </button>
          )}
        </div>
      </div>
      <div className="checkout-button">
        {isMapLocked && hasCompleteInformation ? (
          <button className="button button--hollow button--option" onClick={() => addToCart()}>
            <ShoppingCartIcon />
            {t('options.add_to_cart')}
          </button>
        ) : (
          <button
            className="button button--hollow button--option"
            onClick={() => addToCart()}
            disabled
          >
            <ShoppingCartIcon />
            {t('options.add_to_cart')}
            <span className="button__inner" onClick={() => setOpenDialog('cart-dialog')} />
          </button>
        )}
      </div>
      <PreviewWindow
        selectedProduct={selectedProduct.id}
        products={products}
        filteredVariations={filteredVariations}
        isPreviewActive={isPreviewActive}
        setIsPreviewActive={setIsPreviewActive}
        previewViewport={previewViewport}
        setPreviewViewport={setPreviewViewport}
        centerCoordinates={centerCoordinates}
        cursorCoordinates={cursorCoordinates}
        setCursorCoordinates={setCursorCoordinates}
        previewGeoJSON={previewGeoJSON}
        scrollLimitPolygon={scrollLimitPolygon}
        mapboxToken={mapboxToken}
      />
      <Dialog
        id="lock-dialog"
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={t('dialog.title')}
        content={t('dialog.lock_not_available')}
      />
      <Dialog
        id="cart-dialog"
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={t('dialog.title')}
        content={t('dialog.cart_not_available')}
      />
    </>
  );
};

export default Options;
