import React, { useState } from 'react';
import MapGL, { Marker } from 'react-map-gl';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close-small.svg';
import PropTypes from 'prop-types';

// Workaround for map not showing in production build. See more at https://github.com/mapbox/mapbox-gl-js/issues/10173 and https://github.com/visgl/react-map-gl/pull/1365
import mapboxgl from 'mapbox-gl';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const PreviewControl = (props) => {
  const [settings] = useState({
    dragPan: false,
    dragRotate: false,
    touchRotate: false,
    doubleClickZoom: false,
    scrollZoom: false,
    attributionControl: false,
  });

  return (
    <div className={props.mapLocked ? 'preview-control is-locked' : 'preview-control is-unlocked'}>
      <div className="preview-control__map-wrap">
        <MapGL
          mapboxApiAccessToken={props.mapboxToken}
          {...props.viewport}
          {...settings}
          width={props.width}
          height={props.height}
          mapStyle={{
            version: 8,
            sources: {},
            layers: [],
          }}
          onHover={(e) => {
            // Set cursor coordinates on map hover
            props.setPreviewViewport({
              ...props.previewViewport,
              longitude: e.lngLat[0],
              latitude: e.lngLat[1],
            });
            props.setCursorCoordinates(e.lngLat);
          }}
        >
          {props.cursorCoordinates.length > 0 && (
            <Marker
              longitude={props.cursorCoordinates[0]}
              latitude={props.cursorCoordinates[1]}
              offsetLeft={-20}
              offsetTop={-20}
            >
              <div className="preview-control__cursor">
                <CloseIcon />
              </div>
            </Marker>
          )}
        </MapGL>
      </div>
    </div>
  );
};

PreviewControl.propTypes = {
  previewViewport: PropTypes.shape({
    longitude: PropTypes.number,
    latitude: PropTypes.number,
  }),

  cursorCoordinates: PropTypes.arrayOf(PropTypes.number),
  width: PropTypes.string,
  height: PropTypes.string,
  mapLocked: PropTypes.bool,
};

export default PreviewControl;
