import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from './Dialog';
import ReactMapGL, { Layer, Source } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ReactComponent as ChevronLeftIcon } from '../assets/icons/icon-chevron-left.svg';
import { ReactComponent as TooltipIcon } from '../assets/icons/icon-tooltip.svg';
import { ReactComponent as ResizeBiggerIcon } from '../assets/icons/icon-resize-bigger.svg';
import { ReactComponent as ResizeSmallerIcon } from '../assets/icons/icon-resize-smaller.svg';
import PropTypes from 'prop-types';

// Workaround for map not showing in production build. See more at https://github.com/mapbox/mapbox-gl-js/issues/10173 and https://github.com/visgl/react-map-gl/pull/1365
import mapboxgl from 'mapbox-gl';
import axios from 'axios';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const PreviewWindow = (props) => {
  const { t } = useTranslation('paraskartta');
  const {
    scrollLimitPolygon,
    previewViewport,
    previewGeoJSON,
    setIsPreviewActive,
    isPreviewActive,
    products,
    selectedProduct,
    mapboxToken,
    filteredVariations,
  } = props;
  const [openDialog, setOpenDialog] = useState('');
  const [copyright, setCopyright] = useState('');
  const [mapStyle, setMapStyle] = useState({
    version: 8,
    sources: {},
    layers: [],
  });
  const [previewSize, setPreviewSize] = useState({ width: 282, height: 170 });

  const [settings] = useState({
    dragPan: true,
    dragRotate: false,
    touchRotate: false,
    doubleClickZoom: false,
    scrollZoom: false,
    attributionControl: false,
    keyboard: false,
  });

  const [viewport, setViewport] = useState({
    longitude: 22.51385,
    latitude: 60.41844,
    zoom: 14,
    bearing: -2.1,
    pitch: 0,
  });

  const [geoJSON, setGeoJSON] = useState({
    type: 'FeatureCollection',
    features: [],
  });

  // This is for setting maxBounds for the map
  const [bounds, setBounds] = useState([[], []]);

  const maskLayer = {
    id: 'previewMask',
    type: 'fill',
    source: 'previewMaskGeoJSON',
    paint: {
      'fill-color': '#000000',
      'fill-opacity': 0.7,
    },
  };

  // When area selector is rendered, update this Mapbox'sviewport
  useEffect(() => {
    const productIndex = products.findIndex((x) => x.id === selectedProduct);
    setViewport((viewport) => {
      return {
        ...viewport,
        bearing: 0,
        longitude: previewViewport.longitude,
        latitude: previewViewport.latitude,
        zoom: productIndex > -1 ? products[productIndex]?.omakartta?.map_zoom - 1 : viewport.zoom,
      };
    });
  }, [previewViewport, products, selectedProduct]);

  useEffect(
    (geoJSON) => {
      if (geoJSON !== previewGeoJSON && previewGeoJSON !== undefined) {
        setGeoJSON({
          type: previewGeoJSON.type,
          features: previewGeoJSON.features,
        });
        setBounds(
          [
            scrollLimitPolygon.features[0].geometry.coordinates[0][2],
            //South West corner
            scrollLimitPolygon.features[0].geometry.coordinates[0][0],
          ]
          //North East corner
        );
      }
    },
    [previewGeoJSON, scrollLimitPolygon]
  );

  const isOutOfMaxBounds = (latitude, longitude, maxBounds) => {
    const [[swLng, swLat], [neLng, neLat]] = maxBounds;
    const isIt = longitude < swLng || longitude > neLng || latitude < swLat || latitude > neLat;
    return isIt;
  };

  const getMapStyle = (url) => {
    axios
      .get(url)
      .then((response) => {
        setMapStyle(response.data);
      })
      .catch((err) => console.error(err));
  };

  // Set preview map copyright information
  useEffect(() => {
    if (selectedProduct && products.length > 0) {
      const productIndex = products.findIndex((x) => x.id === selectedProduct);
      if (productIndex > -1) {
        setCopyright(products[productIndex].omakartta?.map_copyright);
        if (products[productIndex].omakartta?.map_style) {
          getMapStyle(products[productIndex].omakartta?.map_style);
        }
      }
    } else {
      setCopyright('');
      setMapStyle({
        version: 8,
        sources: {},
        layers: [],
      });
    }
  }, [selectedProduct, products]);

  // Set visible layers
  useEffect(() => {
    if (filteredVariations.length > 0) {
      const layers = [];
      filteredVariations.forEach((variation) => {
        if (variation.omakartta?.map_layers?.length > 0) {
          variation.omakartta.map_layers.forEach((layer) => {
            if (
              filteredVariations.every(
                (variation) => variation.omakartta?.map_layers?.indexOf(layer) > -1
              ) &&
              layers.indexOf(layer) === -1
            ) {
              layers.push(layer.substring(1));
            }
          });
        }
      });
      setMapStyle((mapStyle) => {
        const newLayers = [...mapStyle.layers]?.map((layer) => {
          if (layers.indexOf(layer.id) > -1) {
            return { ...layer, layout: { ...layer.layout, visibility: 'visible' } };
          } else {
            if (layer?.layout?.visibility) {
              return { ...layer, layout: { ...layer.layout, visibility: 'none' } };
            } else {
              return layer;
            }
          }
        });
        const newStyle = { ...mapStyle, layers: newLayers };
        return newStyle;
      });
    }
  }, [filteredVariations]);

  return (
    <>
      <div
        className={isPreviewActive ? 'preview-window is-active' : 'preview-window'}
        style={{
          width: previewSize.width + 32,
          right: isPreviewActive ? '32px' : -(previewSize.width + 32),
        }}
      >
        <button
          className={
            isPreviewActive ? 'preview-window__toggle is-active' : 'preview-window__toggle'
          }
          onClick={() => setIsPreviewActive(!isPreviewActive)}
        >
          <ChevronLeftIcon />
        </button>
        <div className="preview-window__header">
          <div className="preview-window__title">
            <p>{t('preview.title')}</p>
            <button className="tooltip" onClick={() => setOpenDialog('preview-dialog')}>
              <TooltipIcon />
            </button>
          </div>
          <div className="preview-window__zoom">
            {previewSize.width >= 564 ? (
              <button
                onClick={() =>
                  setPreviewSize({
                    width: previewSize.width * 0.5,
                    height: previewSize.height * 0.5,
                  })
                }
              >
                <ResizeSmallerIcon />
              </button>
            ) : (
              <button
                onClick={() =>
                  setPreviewSize({ width: previewSize.width * 2, height: previewSize.height * 2 })
                }
              >
                <ResizeBiggerIcon />
              </button>
            )}
          </div>
        </div>
        <div
          className="preview-window__container"
          style={{ width: previewSize.width, height: previewSize.height }}
        >
          {previewGeoJSON === undefined && (
            <div className="preview-window__placeholder">
              <p>{t('preview.description')}</p>
            </div>
          )}
          {previewGeoJSON !== undefined && (
            <ReactMapGL
              mapboxApiAccessToken={mapboxToken}
              {...viewport}
              {...settings}
              width={`${previewSize.width}px`}
              height={`${previewSize.height}px`}
              onViewportChange={(nextViewport) => {
                if (
                  !isOutOfMaxBounds(
                    nextViewport.latitude,
                    nextViewport.longitude,
                    bounds // your max bounds
                  )
                ) {
                  setViewport({
                    ...viewport,
                    longitude: nextViewport.longitude,
                    latitude: nextViewport.latitude,
                  });
                  props.setCursorCoordinates([nextViewport.longitude, nextViewport.latitude]);
                }
              }}
              mapStyle={mapStyle}
            >
              {geoJSON.features.length > 0 && (
                <>
                  <Source id="previewMaskGeoJSON" type="geojson" data={geoJSON}>
                    <Layer {...maskLayer} />
                  </Source>
                </>
              )}
            </ReactMapGL>
          )}
        </div>
        <p className="preview-window__copyright" dangerouslySetInnerHTML={{ __html: copyright }} />
      </div>
      <Dialog
        id="preview-dialog"
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={t('dialog.preview_tooltip_title')}
        html={t('dialog.preview_tooltip')}
      />
    </>
  );
};

PreviewWindow.propTypes = {
  selectedProduct: PropTypes.number,
  products: PropTypes.array,
};

export default PreviewWindow;
