import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as ChevronLeftIcon } from '../assets/icons/icon-chevron-left.svg';
import { ReactComponent as CloseIcon } from '../assets/icons/icon-close-small.svg';
import Dialog from './Dialog';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TooltipIcon } from '../assets/icons/icon-tooltip.svg';
import PropTypes from 'prop-types';

const Select = (props) => {
  const { t } = useTranslation('paraskartta');
  const [value, setValue] = useState('');
  const [isActive, setActive] = useState(false);
  const [openDialog, setOpenDialog] = useState('');

  // Handle the value change
  const handleChange = useCallback(
    (newValue) => {
      setValue(newValue);
      setActive(false);

      const newFilters = { ...props.filters };
      newFilters[props.id] = newValue;
      props.setFilters(newFilters);
    },
    [props, value]
  );

  // Handle the input field opening click
  const handleClick = () => {
    const newFilters = { ...props.filters };
    delete newFilters[props.id];
    setActive(!isActive);
    props.setFilters(newFilters);
  };

  // Handle click outside the component when it is active
  const handleBlur = (currentValue) => {
    setActive(!isActive);

    if (currentValue !== '') {
      const newFilters = { ...props.filters };
      newFilters[props.id] = currentValue;
      props.setFilters(newFilters);
    }
  };

  // Handle input clear
  const handleClear = () => {
    setValue('');

    if (!isActive) {
      const newFilters = { ...props.filters };
      delete newFilters[props.id];
      props.setFilters(newFilters);
    }
  };

  // Make a list of options as buttons
  const options = props.options
    ? props.options.map((newValue, i) => {
        const descriptions = [];
        props.variations.forEach((variation) => {
          variation.descriptions.forEach((description) => {
            if (descriptions.findIndex((x) => x.option === description.option) === -1) {
              descriptions.push(description);
            }
          });
        });
        const index = descriptions.findIndex((x) => x.option === newValue);
        // Check if filtered varitions has matching value
        if (
          !props.isVariation ||
          props.filteredVariations.some((x) => {
            return x.attributes.includes(newValue);
          })
        ) {
          // Return button to be able to change the value
          return (
            <li key={i} className={newValue === value ? 'is-active' : ''}>
              <button onClick={() => handleChange(newValue)}>{newValue}</button>
              {index > -1 && descriptions[index].description !== '' && (
                <button
                  className="tooltip"
                  data-open={`dialog-${newValue.replace(/\s/g, '')}`}
                  onClick={() => setOpenDialog(`dialog-${newValue.replace(/\s/g, '')}`)}
                >
                  <TooltipIcon />
                </button>
              )}
            </li>
          );
        }
        // If there are no matching variations with that value
        else {
          // Return "disabled" button not to be able to change the value
          return (
            <li className="disabled" key={i}>
              <button onClick={() => setOpenDialog('option-dialog')}>{newValue}</button>
              {index > -1 && descriptions[index].description !== '' && (
                <button
                  className="tooltip"
                  data-open={`dialog-${newValue.replace(/\s/g, '')}`}
                  onClick={() => setOpenDialog(`dialog-${newValue.replace(/\s/g, '')}`)}
                >
                  <TooltipIcon />
                </button>
              )}
            </li>
          );
        }
      })
    : [];

  // Make a list of dialogs for product tooltips
  const dialogs = props.options
    ? props.options.map((item, i) => {
        const descriptions = [];
        props.variations.forEach((variation) => {
          variation.descriptions.forEach((description) => {
            if (descriptions.findIndex((x) => x.option === description.option) === -1) {
              descriptions.push(description);
            }
          });
        });
        const index = descriptions.findIndex((x) => x.option === item);
        if (index > -1 && descriptions[index].description !== '') {
          return (
            <Dialog
              key={i}
              id={`dialog-${item.replace(/\s/g, '')}`}
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              title={descriptions[index].option}
              html={descriptions[index].description}
            />
          );
        } else return null;
      })
    : [];

  const ref = useRef();
  const optionsList = (
    <ul className={isActive ? 'select__input-options is-active' : 'select__input-options'}>
      {options}
    </ul>
  );

  useEffect(() => {
    const pos = ref.current.getBoundingClientRect();
    document.getElementById('select-container').style.top = `${pos.top - 43}px`;
  }, [isActive]);

  return (
    <>
      <div
        className={isActive ? 'close-select is-active' : 'close-select'}
        onClick={() => handleBlur(value)}
      />
      <div className={props.isClickable ? 'select is-active' : 'select is-disabled'} ref={ref}>
        <div className="select__label-wrap">
          <div className="select__label">
            <p>{props.label}</p>
          </div>
          {props.tooltip !== '' && (
            <button className="tooltip" onClick={() => setOpenDialog('attr-dialog')}>
              <TooltipIcon />
            </button>
          )}
        </div>
        <div className="select__input-wrap">
          <button className="select__input" onClick={() => handleClick(value)}>
            {!value || value === '' ? props.placeholder : value}
            <ChevronLeftIcon />
          </button>
          {value !== '' && (
            <button className="select__input-clear" onClick={() => handleClear(value)}>
              <CloseIcon />
            </button>
          )}
          {isActive &&
            ReactDOM.createPortal(optionsList, document.getElementById('select-container'))}
        </div>
      </div>
      <Dialog
        id="attr-dialog"
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={props.label}
        html={props.tooltip}
      />
      <Dialog
        id="option-dialog"
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        title={t('dialog.title')}
        content={t('dialog.option_not_available')}
      />
      {dialogs}
    </>
  );
};

Select.propTypes = {
  filters: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  options: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,

  filteredVariations: PropTypes.arrayOf(
    PropTypes.shape({
      descriptions: PropTypes.arrayOf(
        PropTypes.shape({
          option: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      attributes: PropTypes.arrayOf(PropTypes.string),
    })
  ),

  variations: PropTypes.arrayOf(
    PropTypes.shape({
      descriptions: PropTypes.arrayOf(
        PropTypes.shape({
          option: PropTypes.string,
          description: PropTypes.string,
        })
      ),
      attributes: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

export default Select;
